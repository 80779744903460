<script>
import axios from 'axios'
import { getInstance } from '../../auth/index'
const authService = getInstance()
export default {
  async updateListing (id, listing) {
    const token = await authService.getTokenSilently()
    const URL = process.env.VUE_APP_APIURL + 'articles/listings/' + id
    var listingData = {
      addressLine1: listing.addressLine1,
      addressLine2: listing.addressLine2,
      addressLine3: listing.addressLine3,
      category: listing.category,
      city: listing.city,
      contactDetails: listing.contactDetails,
      content: listing.content,
      country: listing.country,
      county: listing.county,
      displayTag: listing.displayTag,
      hoursOfBusiness: listing.hoursOfBusiness,
      imageUrl: listing.imageUrl,
      name: listing.name,
      postCode: listing.postCode,
      publishStatus: listing.publishStatus,
      summary: listing.summary,
      superTag: listing.superTag,
      tags: listing.tags
    }
    const ops = {
      method: 'PUT',
      headers: { 'content-type': 'application/json' },
      data: listingData,
      url: URL
    }
    axios.defaults.headers.common['x-api-key'] = process.env.VUE_APP_APIKEY
    axios.defaults.headers.common.Authorization = `Bearer ${token}`
    try {
      const res = await axios(ops)
      return res
    } catch (error) {
      // eslint-disable-next-line
      console.error(error.response)
      return error.response
    }
  }
}
</script>
